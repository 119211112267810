<template>
  <div
    class="mobile-sidebar"
    :class="{'--fixed': isHeaderFixed}"
  >
    <div
      class="mobile-sidebar__content"
      :class="{'--level2': Boolean(selectedCategory)}"
    >
      <div class="mobile-sidebar__level-1">

        <div class="menu-categories">
          <template
            v-for="(item) in menuCategories"
            :key="`menu-level-1-${ item.slug }`"
          >
            <div
              class="button-link"
              @click="() => clickItemCategory(item)"
            >
              {{ item.name }}

              <img
                class="--arrow"
                src="~/assets/img/svg/common/arrow.svg"
                alt="далее"
              />
            </div>
          </template>
        </div>

        <div class="menu-controls">
          <nuxt-link
            to="/cart"
            class="button-link"
            @click.capture="routeCartPage"
          >
            <img
              class="--left-icon"
              src="~/assets/img/svg/common/shopping.svg"
              alt="корзина"
            />
            Корзина
            <span v-if="countCartList" class="--badge --black">{{ countCartList }}</span>
          </nuxt-link>
          <nuxt-link to="/wishlist" class="button-link" @click="closeSidebar">
            <img
              class="--left-icon"
              src="~/assets/img/svg/common/heart.svg"
              alt="избранное"
            />
            Избранное
            <span v-if="countWishList" class="--badge --red">{{ countWishList }}</span>
          </nuxt-link>
          <nuxt-link
            :to="Boolean(userName) ? '/profile' : '/authorization'"
            class="button-link"
            @click="closeSidebar"
          >
            <img
              class="--left-icon"
              src="~/assets/img/svg/common/user.svg"
              alt="пользователь"
            />
            {{ userName || "Войти в личный кабинет" }}
            <div v-if="isAuth" class="--badge --green --icon">
              <img
                src="~/assets/img/svg/common/check.svg"
                alt="авторизован"
              >
            </div>
          </nuxt-link>
        </div>

        <div class="menu-links">
          <nuxt-link
            v-for="(link) in menuLinks"
            :key="`menu-links-${ link.path }`"
            :to="`/${ link.path }`"
            @click.capture="(event: any) => clickLinkRightMenu(event, link)"
          >{{ link.title }}</nuxt-link>
        </div>

      </div>
      <div class="mobile-sidebar__level-2">
        <div class="button-back">
          <div class="button-link" @click="closeItemCategory">
            <img
              src="~/assets/img/svg/common/arrow.svg"
              alt="назад"
            />
            Назад
          </div>
        </div>
        <div class="furniture-items">
          <div
            class="furniture-items__title h3 semibold"
            @click="clickCategory"
          >
            {{ selectedCategoryTitle }}
          </div>
          <div class="furniture-items__list">
            <template
              v-for="(item) in selectedCategoryChildren"
              :key="`menu-level-2-${ item.slug }`"
            >
              <div
                class="button-link"
                @click="() => clickItemCategory(item)"
              >
                {{ item.name }}

                <img
                  class="--arrow"
                  src="~/assets/img/svg/common/arrow.svg"
                  alt="назад"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobileSidebar } from '~/composables/useMobileSidebar';
import { catalogStore } from '#sitis/stores/modules/catalog-store';
import { computed } from 'vue';
import { wishStore as useWishStore } from '#sitis/stores/modules/wish-store';
import { cartStore as useCartStore } from '#sitis/stores/modules/cart-store';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { useCommonStore } from '~/store/common';
import { useCreateOrder3dProject } from '~/composables/useCreateOrder3dProject';
import { useNotificationModal } from '~/composables/useNotificationModal';

const {
  isOpen,
  changeOpen
} = useMobileSidebar();
const {
  openCreateOrder3dProjectOpen
} = useCreateOrder3dProject();
const {
  openNotificationModal
} = useNotificationModal();
const {
  $ym
} = useNuxtApp();
const $route = useRoute();
const $router = useRouter();

const authStore = useSitisAuth();
const wishStore = useWishStore();
const cartStore = useCartStore();
const cmsStore = cmsContentStore();
const storeCatalog = catalogStore();
const commonStore = useCommonStore();
const {
  currentUser
} = useSitisAuth();

const selectedCategory: any = ref(null);

const userName = computed(() => {
  const currentUser = authStore.currentUser?.value;
  return currentUser?.name || currentUser?.email || currentUser?.phone || ""
});
const menuCategories = computed(() => {
  let categories = storeCatalog?.$state?.categories || [];
  categories = categories.map((item: any) => ({
    ...item,
    items: (item?.items || []).filter((t: any) => t?.showInMenu)
  }))
    .filter((t: any) => t?.showInMenu)
    .filter((t: any) => t.depth < 4);

  let list: any = categories.filter((t) => t.depth === 1);
  list = list.map((item: any) => {
    const children = categories
      .filter((t: any) => t.parentId === item.id)
      .map((t: any) => {
        return {
          ...t,
          children: categories.filter((_t: any) => _t.parentId === t.id)
        }
      })

    return {
      ...item,
      children: children
    }
  });

  let indexKomnataDljaPodrostka = list.findIndex((t: any) => t.slug === 'komnata-dlja-podrostka');
  list.splice((indexKomnataDljaPodrostka + 1), 0, {
    url: "https://miasofiahome.ru",
    name: "Мебель для родителей"
  });

  // list.push({
  //   url: "/akcii",
  //   name: "Скидки"
  // });

  return list;
});
const countCartList = computed(() => cartStore.itemsCount);
const countWishList = computed(() => wishStore.wishlistCount);
const isAuth = computed(() => Boolean(currentUser?.value?.id));
const menuLinks = computed(() => (cmsStore?.menu || []).filter((itemMenu) => !itemMenu.isHidden && !itemMenu.isHome));
const isHeaderFixed = computed(() => {
  return commonStore._headerFixed
})
const selectedCategoryTitle = computed(() => {
  return selectedCategory.value?.name
})
const selectedCategoryChildren = computed(() => {
  return selectedCategory.value?.children || []
});

const clickCategory = () => {
  if ((selectedCategory.value.url || '').includes('https://')) {
    window.open(selectedCategory.value.url, '_blank');
    closeSidebar();
    return;
  }

  const currentUrl = (($route.path || '').split("/f/")?.[0] || '').split('?')?.[0] || '';
  if (selectedCategory.value.url === currentUrl) {
    window.location.href = selectedCategory.value.url;
    return;
  }

  $router.push(selectedCategory.value.url);
  closeSidebar();
  return
}
const clickItemCategory = (item: any) => {
  if ((item.children || []).length <= 0) {
    if ((item.url || '').includes('https://')) {
      window.open(item.url, '_blank');
      closeSidebar();
      return;
    }

    const currentUrl = (($route.path || '').split("/f/")?.[0] || '').split('?')?.[0] || '';
    if (item.url === currentUrl) {
      window.location.href = item.url;
      return;
    }

    $router.push(item.url);
    closeSidebar();
    return
  }
  selectedCategory.value = item;
}
const clickLinkRightMenu = (event: any, item: any) => {
  if (item.alias === '3d-projects') {
    event.preventDefault();
    event.stopPropagation();
    openCreateOrder3dProjectOpen();
    closeItemCategory();
    closeSidebar();
    return
  }
}

const closeItemCategory = () => {
  const parentCategory = menuCategories.value.find((t: any) => t.id === selectedCategory.value?.parentId) || null;
  selectedCategory.value = parentCategory;
}
const closeSidebar = () => {
  changeOpen(false);
}

const routeCartPage = (event: any) => {
  closeSidebar();
  setYMGoalCart();
  if (countCartList.value <= 0) {
    event.preventDefault();
    openNotificationModal({
      title: "Ваша корзина пока пуста.",
      message: `
        <p>1. Выберите товары в каталоге и сложите их в корзину.</p>
        <p>2. Затем перейдите в корзину, чтобы проверить состав заказа и завершить его оформление.</p>
      `
    })
    return
  }
}
const setYMGoalCart = () => {
  if (typeof $ym === 'function') {
    countCartList.value > 0 ? $ym('reachGoal', 'click_cart_button') : $ym('reachGoal', 'click_empty_cart_button');
  }
}
</script>

<style scoped lang="scss">
.mobile-sidebar {
  position: fixed;
  z-index: 89;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: white;
  overflow: auto;

  &.--fixed {
    .mobile-sidebar__content {
      padding-top: 100px;
    }
  }
}
.mobile-sidebar__content {
  display: flex;
  width: 100vw;
  padding: 0 20px;
  padding-top: 128px;
  padding-bottom: 32px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  & > * {
    width: calc(100vw - 40px);
    min-width: calc(100vw - 40px);
    margin-left: 20px;
    transition: transform 0.2s;

    &:first-child {
      margin-left: 0;
    }
  }
  &.--level2 > * {
    transform: translateX(calc((100vw - 20px) * -1));
  }
}

.menu-categories {
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.menu-controls {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > * {
    margin-top: 10px;
    min-height: 54px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.menu-links {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -10px;
  padding-top: 32px;

  & > * {
    padding: 16px 24px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 12px;
    border: 1px solid #EBEBEB;

    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #000;
  }
}

.furniture-items {
  margin-top: 40px;
}
.furniture-items__title {
  margin-bottom: 32px;
  color: #000000;
}
.furniture-items__list {
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.button-link {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background: #F4F3F1;

  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: #000;

  .--badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 20px;
    min-width: 20px;
    border-radius: 100px;

    font-size: 9px;
    font-weight: 500;
  }
  .--badge.--black {
    background-color: #000000;
    color: white;
  }
  .--badge.--red {
    background-color: #ED908C;
    color: white;
  }
  .--badge.--green {
    background-color: #D3E6C6;
  }
  .--badge.--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .--arrow {
    margin-left: auto;
    width: 14px;
    height: 14px;
    object-fit: contain;
    transform: rotate(180deg);
  }
  .--left-icon {
    width: 23px;
    height: 23px;
    object-fit: contain;
    margin-right: 10px;
  }
}
.button-back {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-right: 10px;
  }
}
</style>
